import React, { useState, useEffect } from 'react';
import axios from 'axios';

const VisitSites = () => {
    const [sites, setSites] = useState([]);
    const [balance, setBalance] = useState(0.0); // State for user's balance
    const [timeLeft, setTimeLeft] = useState(0);
    const [activeSite, setActiveSite] = useState(null);
    const [userId, setUserId] = useState(null); // State for user ID
    const [toastMessage, setToastMessage] = useState(''); // State for toast message
    const [showToast, setShowToast] = useState(false); // State to show/hide toast

    useEffect(() => {
        const tg = window.Telegram.WebApp;
        tg.ready();
        const user = tg.initDataUnsafe.user;
        if (user && user.id) {
            setUserId(user.id);
            fetchBalance(user.id);
        }
    }, []);

    const fetchBalance = async (userId) => {
        try {
            const response = await axios.get(`https://tronads.nazmods.net/get_user.php?telegram_user_id=${userId}`);
            if (response.data.success) {
                setBalance(parseFloat(response.data.user.balance));
            } else {
                showToastMessage('Failed to fetch user balance.');
            }
        } catch (error) {
            console.error('Error fetching user balance:', error);
        }
    };

    useEffect(() => {
        const fetchSites = async () => {
            try {
                const response = await axios.get('https://tronads.nazmods.net/api/get_sites.php');
                if (response.data.success) {
                    setSites(response.data.sites);
                } else {
                    showToastMessage('Failed to load sites.');
                }
            } catch (error) {
                console.error('Error fetching sites:', error);
            }
        };

        fetchSites();
    }, []);

    useEffect(() => {
        if (timeLeft > 0) {
            const timer = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
            return () => clearTimeout(timer);
        } else if (timeLeft === 0 && activeSite) {
            handleReward(activeSite);
        }
    }, [timeLeft, activeSite]);

    const handleVisit = (site) => {
        if (site.already_visited) {
            showToastMessage('You have already visited this site today.');
            return;
        }
        setActiveSite(site);
        setTimeLeft(site.visit_duration); // Set the timer based on the visit duration
        window.open(site.url, '_blank'); // Open the site in a new tab
    };

    const handleReward = async (site) => {
        try {
            const response = await axios.post('https://tronads.nazmods.net/api/reward_user.php', {
                telegram_user_id: userId,
                site_id: site.id,  // Ensure site_id is passed here
                reward: site.reward,
            });

            if (response.data.success) {
                setBalance(prevBalance => prevBalance + parseFloat(site.reward)); // Update the balance state
                showToastMessage(`You have received ${site.reward} TRX as a reward!`);
                setActiveSite(null);
            } else {
                showToastMessage(response.data.message);
            }
        } catch (error) {
            console.error('Error rewarding user:', error);
        }
    };

    const showToastMessage = (message) => {
        setToastMessage(message);
        setShowToast(true);
        setTimeout(() => setShowToast(false), 3000); // Hide toast after 3 seconds
    };

    return (
        <div className="visit-sites relative max-w-4xl mx-auto p-4">
            <div className="user-balance text-center mb-6">
                {/* <h2 className="text-3xl font-bold">Current Balance: {balance.toFixed(8)} DOGE</h2> */}
                <h2 className='text-3xl font-bold'>Tasks</h2>
            </div>

            <div className="space-y-4">
                {sites.map((site) => (
                    <div
                        key={site.id}
                        className="visit-site-item bg-yellow-100 rounded-lg shadow-lg p-4 flex items-center justify-between"
                        style={{ transition: 'transform 0.2s', transform: activeSite === site ? 'scale(1.05)' : 'scale(1)' }}
                    >
                        <div className="visit-site-info flex items-center">
                            <img src="/assets/coin.png" alt="Icon" className="mr-4 w-12 h-12 rounded-full" />
                            <div>
                                <div className="font-bold text-xl mb-1">{site.title}</div>
                                <div className="text-gray-600">{site.description}</div>
                                <div className="text-yellow-600 font-bold mt-2 flex items-center">
                                    {/* <img src="/assets/coin.png" alt="Coin" className="inline mr-1 w-5 h-5" /> */}
                                    {site.reward} TRX
                                </div>
                            </div>
                        </div>
                        <button
                            onClick={() => handleVisit(site)}
                            className={`font-bold py-2 px-6 rounded-full ${site.already_visited ? 'bg-green-500 text-white cursor-not-allowed flex items-center justify-center' : 'bg-yellow-500 hover:bg-yellow-600 text-white'}`}
                            disabled={site.already_visited}
                            style={{ fontSize: '1rem', transition: 'background-color 0.2s' }}
                        >
                            {site.already_visited ? '✔️' : (timeLeft > 0 && activeSite === site ? `Wait ${timeLeft}s` : 'Visit')}
                        </button>
                    </div>

                ))}

                <center>
                    <a href="https://www.example.com" target="_blank">
                        <button class="bg-gray-500 hover:bg-black-400 text-white font-bold py-2 px-4 border-b-4 border-blue-700 hover:border-blue-500 rounded">
                            Promote your Ads Here
                        </button>
                    </a>
                </center>

            </div>

            {showToast && (
                <div className="toast ">
                    {toastMessage}
                </div>
            )}
        </div>
    );
};

export default VisitSites;
