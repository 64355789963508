import React, { useEffect, useState } from 'react';
import Footer from '../components/Footer';
import axios from 'axios';
import '../App.css';

const TaskPage = () => {
  const [userId, setUserId] = useState(null);
  const [isMember, setIsMember] = useState(false);
  const [hasClaimed, setHasClaimed] = useState(false);
  const [isClaimable, setIsClaimable] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [showToast, setShowToast] = useState(false);

  useEffect(() => {
    const tg = window.Telegram.WebApp;
    tg.ready();
    tg.expand();
    tg.enableClosingConfirmation();

    const user = tg.initDataUnsafe.user;
    if (user && user.id) {
      setUserId(user.id);
      checkMembership(user.id);
    }
  }, []);

  const checkMembership = async (userId) => {
    try {
      const response = await axios.get(`https://tronads.nazmods.net/api/check_membership.php?user_id=${userId}`);
      const data = response.data;
      if (data.is_member) {
        setIsMember(true);
        if (data.has_claimed) {
          setHasClaimed(true);
        } else {
          setIsClaimable(true);
        }
      } else {
        setIsClaimable(false);
      }
    } catch (error) {
      console.error('Error checking membership:', error);
    }
  };

  const claimTask = async () => {
    try {
      const response = await axios.post('https://tronads.nazmods.net/api/claim_task.php', {
        user_id: userId
      });
      const data = response.data;
      if (data.success) {
        setHasClaimed(true);
        setToastMessage('Task completed successfully! You earned 0.001 TRX.');
        setShowToast(true);
        setTimeout(() => setShowToast(false), 3000);
      } else {
        alert(data.message);
      }
    } catch (error) {
      console.error('Error claiming task:', error);
    }
  };

  const handleClaimButtonClick = () => {
    if (isMember) {
      claimTask();
    } else {
      window.location.href = 'https://t.me/AdBeastBots';
    }
  };

  return (
    <div>
      <main id="mainTasks">
        <section className="task-section">
          <div className="container">
            <div className="task-section__box">
              <div className="main-top">
                <h1 className="title">New tasks</h1>
                <p>Complete tasks and get bonuses</p>
              </div>
              <ul className="tasks-list tasks_block">
                <li id="taskItm_1" className={`tasks-list__item ${hasClaimed ? 'claimed' : ''}`}>
                  <div className="task-card">
                    <span className="task-card__icon">
                      <img width="24" height="24" src="/assets/tg.svg" alt="icon" />
                    </span>
                    <div className="task-card__box">
                      <span className="task-card__title">Join our news channel</span>
                      <span className="task-card__mining">
                        <span className="task-card__mining-icon">
                          <img src="/assets/coin.png" alt="" />
                        </span>
                        <span className="task-card__value"><b>0.001</b> TRX</span>
                      </span>
                    </div>
                    <div className="task_button">
                      <button
                        onClick={handleClaimButtonClick}
                        id="claim-button"
                        className="main-btn claim_task"
                        data-taskid="1"
                        disabled={hasClaimed}
                      >
                        {isClaimable ? 'Claim' : 'Join'}
                      </button>
                    </div>
                    <div className="task_claimed_icon">
                      <img src="/assets/check.png" width="22" alt="" />
                    </div>
                  </div>
                </li>
              </ul>


              <a
                id="refs_invite_friends"
                href="/visit"
                className="main-btn"
              >
                🔥Make Money
              </a>


            </div>
          </div>
        </section>
      </main>

      <Footer />
      {showToast && (
        <div className="toast">
          {toastMessage}
        </div>
      )}
    </div>
  );
};

export default TaskPage;
