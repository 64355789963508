import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../App.css';
import Footer from '../components/Footer'; // Adjust the path as necessary

function Homepage() {
  const [balance, setBalance] = useState(0.0);
  const [speed, setSpeed] = useState(0.0);
  const [userId, setUserId] = useState(null);
  const [miningInterval, setMiningInterval] = useState(null);

  useEffect(() => {
    const tg = window.Telegram.WebApp;
    tg.ready();
    tg.expand();
    tg.enableClosingConfirmation();

    const user = tg.initDataUnsafe.user;
    if (user && user.id) {
      setUserId(user.id);
      fetchBalance(user.id);
    }
  }, []);

  // useEffect(() => {
  //   if (userId !== null) {
  //     if (miningInterval) {
  //       clearInterval(miningInterval);
  //     }
  //     const interval = setInterval(() => {
  //       setBalance(prevBalance => {
  //         const newBalance = prevBalance + speed * 0.00000001;
  //         updateBalance(userId, newBalance);
  //         return newBalance;
  //       });
  //     }, 1000);
  //     setMiningInterval(interval);
  //   }
  // }, [userId, speed]);

  const fetchBalance = async (userId) => {
    try {
      const response = await axios.get(`https://tronads.nazmods.net/api/get_user.php?telegram_user_id=${userId}`);
      const data = response.data;
      if (data.success) {
        setBalance(parseFloat(data.user.balance));
        setSpeed(parseFloat(data.user.speed));
      } else {
        alert(data.message);
      }
    } catch (error) {
      console.error('Error fetching balance:', error);
    }
  };

  // const updateBalance = async (userId, newBalance) => {
  //   try {
  //     await axios.post('https://tronads.nazmods.net/api/update_balance.php', {
  //       user_id: userId,
  //       balance: newBalance.toFixed(8),
  //     });
  //   } catch (error) {
  //     console.error('Error updating balance:', error);
  //   }
  // };

  return (
    <div>
      <br /><br />
      <br /><br /><br /><br /><br /><br /><br /><br />
      <main id="mainMining" style={{ dataType: "doge" }}>
        <section className="start-section">
          <div className="container">
            <div className="start-section__box">
              <div className="main-info">
                <div className="main-info__inner">
                  <img width="40" height="40" src="/assets/coin.png" alt="image" />
                  <span className="main-info__value"><b id="balance">{balance.toFixed(8)}</b> TRX</span>
                </div>
                <div className="main-info__mining">
                  {/* <img src="/assets/trx.webp" alt="" style={{ height: "24px", width: "24px", borderRadius: "50%" }} />
                  <span className="main-info__currency"><b id="speed">{speed.toFixed(2)}</b> TH/s</span> */}
                </div>
                <div className="main-info__btns">
                  <a className="main-btn main-btn--mode" href="/withdraw">Withdraw</a>
                  <a className="main-btn" href="/visit">Earn </a>
                </div>
              </div>
              <div className="mining">

              </div>
                  <a className="main-btn" href="/visit">🔥Make Money </a>
                  <br /><br />
              
            </div>
          </div>
        </section>



      </main>
      {/* <div className="bg-white rounded-lg shadow-lg p-6 text-center" style={{ width: "300px" }}>
        <div className="text-lg mb-4"><span id="speed">{speed.toFixed(2)}</span> TH/s</div>
        <div className="flex justify-between">
          <button className="bg-yellow-500 hover:bg-yellow-600 text-white font-bold py-2 px-4 rounded">Claim</button>
          <button className="bg-yellow-400 hover:bg-yellow-500 text-white font-bold py-2 px-4 rounded">Boost</button>
        </div>
      </div> */}

      <Footer />
    </div>
  );
}

export default Homepage;
