// src/components/Footer.js
import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <ul className="bar">
      <li className="bar__item">
        <Link to="/" className="bar__link active">
          <span className="bar__icon">
            <img src="/assets/home.png" alt="Mining" />
          </span>
          Home
        </Link>
      </li>
      <li className="bar__item">
        <Link to="/wallet" className="bar__link">
          <span className="bar__icon">
            <img src="/assets/wallet.png" alt="Wallet" />
          </span>
          Wallet
        </Link>
      </li>
      <li className="bar__item">
        <Link to="/refer" className="bar__link">
          <span className="bar__icon">
            <img src="/assets/invites.png" alt="Friends" />
          </span>
          Friends
        </Link>
      </li>
      <li className="bar__item">
        <Link to="/task" className="bar__link">
          <span className="bar__icon">
            <img src="/assets/task.png" alt="Tasks" />
          </span>
          Tasks
        </Link>
      </li>
    </ul>
  );
};

export default Footer;
